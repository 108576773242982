import { AbilityDefinitionModel } from "@/models/AbilityDefinitionModel";
import { AbilityHelper } from "@/helpers/AbilityHelper";
import { PermissionModel } from "@/models/PermissionModel";
import { AbilityDataModel } from "@/models/AbilityDataModel";

export const allPermission: PermissionModel[] = [
  // * Group MISC
  { action: "READ", target: "DASHBOARD", commonAccess: true },
  // ? Help Center only shown in SmartChat
  // ? Header Contact CS only shown in SmartChat
  // ? Download Manual only Shown in SmartChat

  /**
   * * PRODUCT Group
   */
  {
    action: "READ",
    target: "CREATE_PRODUCT",
    allowedCountries: ["ID"],
    userPermissionIndex: 6,
  },
  {
    action: "CREATE",
    target: "PRODUCT",
    allowedCountries: ["ID"],
    userPermissionIndex: 6,
  },
  {
    action: "READ",
    target: "PRODUCT",
    allowedCountries: ["ID"],
    userPermissionIndex: 7,
  },
  {
    action: "ACTIVATE",
    target: "PRODUCT",
    allowedCountries: ["ID"],
    userPermissionIndex: 7,
  },
  {
    action: "UPDATE",
    target: "PRODUCT",
    allowedCountries: ["ID"],
    userPermissionIndex: 7,
  },
  {
    action: "DELETE",
    target: "PRODUCT",
    allowedCountries: ["ID"],
    userPermissionIndex: 7,
  },
  {
    action: "READ",
    target: "CATEGORY",
    allowedCountries: ["ID"],
    userPermissionIndex: 8,
  },
  {
    action: "CREATE",
    target: "CATEGORY",
    allowedCountries: ["ID"],
    userPermissionIndex: 8,
  },
  {
    action: "UPDATE",
    target: "CATEGORY",
    allowedCountries: ["ID"],
    userPermissionIndex: 8,
  },
  {
    action: "DELETE",
    target: "CATEGORY",
    allowedCountries: ["ID"],
    userPermissionIndex: 8,
  },
  /**
   * * TRANSACTION Group
   */
  {
    action: "READ",
    target: "TRANSACTION",
    allowedCountries: ["ID"],
    userPermissionIndex: 9,
  },
  {
    action: "CREATE",
    target: "TRANSACTION",
    allowedCountries: ["ID"],
    userPermissionIndex: 9,
  },
  {
    action: "UPDATE",
    target: "TRANSACTION",
    allowedCountries: ["ID"],
    userPermissionIndex: 9,
  },
  {
    action: "DELETE",
    target: "TRANSACTION",
    allowedCountries: ["ID"],
    userPermissionIndex: 9,
  },
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_TRANSACTION_BUTTON_CREATE",
    userPermissionIndex: 9,
  },
  /**
   * * WHATSAPP Group
   */
  { action: "READ", target: "WHATSAPP_TEMPLATE", userPermissionIndex: 4 },
  { action: "CREATE", target: "WHATSAPP_TEMPLATE", userPermissionIndex: 4 },
  { action: "UPDATE", target: "WHATSAPP_TEMPLATE", userPermissionIndex: 4 },
  { action: "DELETE", target: "WHATSAPP_TEMPLATE", userPermissionIndex: 4 },
  {
    action: "READ",
    target: "WHATSAPP_BROADCAST",
    userPermissionIndex: 3,
  },
  {
    action: "CREATE",
    target: "WHATSAPP_BROADCAST",
    userPermissionIndex: 3,
  },
  {
    action: "UPDATE",
    target: "WHATSAPP_BROADCAST",
    userPermissionIndex: 3,
  },
  {
    action: "DELETE",
    target: "WHATSAPP_BROADCAST",
    userPermissionIndex: 3,
  },
  { action: "READ", target: "WHATSAPP_CHATBOT", userPermissionIndex: 5 },
  { action: "CREATE", target: "WHATSAPP_CHATBOT", userPermissionIndex: 5 },
  { action: "UPDATE", target: "WHATSAPP_CHATBOT", userPermissionIndex: 5 },
  { action: "DELETE", target: "WHATSAPP_CHATBOT", userPermissionIndex: 5 },
  /**
   * * VIBER Group
   * ? Only shown in NEO KKT
   */
  /**
   * * SOCIAL Group
   * ? Only shown in NEO KKT
   */
  /**
   * * CUSTOMER SERVICE Group
   */
  {
    action: "READ",
    target: "CUSTOMER_SERVICE_INBOX",
    userPermissionIndex: 1,
  },
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_INBOX",
    userPermissionIndex: 1,
  },
  {
    action: "UPDATE",
    target: "CUSTOMER_SERVICE_INBOX",
    userPermissionIndex: 1,
  },
  {
    action: "DELETE",
    target: "CUSTOMER_SERVICE_INBOX",
    userPermissionIndex: 1,
  },
  {
    action: "READ",
    target: "CUSTOMER_SERVICE_INBOX_CHANNEL_FILTER_BUTTON_CONNECT",
    commonAccess: true,
  },
  {
    action: "READ",
    target: "CUSTOMER_SERVICE_HISTORY",
    userPermissionIndex: 2,
  },
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_HISTORY",
    userPermissionIndex: 2,
  },
  {
    action: "UPDATE",
    target: "CUSTOMER_SERVICE_HISTORY",
    userPermissionIndex: 2,
  },
  {
    action: "DELETE",
    target: "CUSTOMER_SERVICE_HISTORY",
    userPermissionIndex: 2,
  },
  {
    action: "READ",
    target: "CUSTOMER_SERVICE_SETTING",
    userPermissionIndex: 0,
  },
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_SETTING",
    userPermissionIndex: 0,
  },
  {
    action: "UPDATE",
    target: "CUSTOMER_SERVICE_SETTING",
    userPermissionIndex: 0,
  },
  {
    action: "DELETE",
    target: "CUSTOMER_SERVICE_SETTING",
    userPermissionIndex: 0,
  },
  // ? Chat Settings Only shown in Neo KKT
  /**
   * * CALL CENTER Group
   * ? Only shown in NEO KKT
   */
  /**
   * * PERFORMANCE Group
   */
  { action: "READ", target: "CS_PERFORMANCE", userPermissionIndex: 10 },
  {
    action: "READ",
    target: "CUSTOM_DASHBOARD",
    clientPermissionIndex: 36,
  },
  {
    action: "READ",
    target: "VIEW_CUSTOM_DASHBOARD_A",
    userPermissionIndex: 34,
  },
  {
    action: "READ",
    target: "VIEW_CUSTOM_DASHBOARD_B",
    userPermissionIndex: 35,
  },
  {
    action: "READ",
    target: "VIEW_CUSTOM_DASHBOARD_C",
    userPermissionIndex: 36,
  },
  {
    action: "READ",
    target: "VIEW_CUSTOM_DASHBOARD_D",
    userPermissionIndex: 37,
  },
  {
    action: "READ",
    target: "VIEW_CUSTOM_DASHBOARD_E",
    userPermissionIndex: 38,
  },
  /**
   * * CRM Group
   */
  { action: "READ", target: "CONTACT", userPermissionIndex: 11 },
  { action: "CREATE", target: "CONTACT", userPermissionIndex: 11 },
  { action: "UPDATE", target: "CONTACT", userPermissionIndex: 11 },
  { action: "DELETE", target: "CONTACT", userPermissionIndex: 11 },
  { action: "READ", target: "CUSTOM_FIELDS", commonAccess: true },
  { action: "CREATE", target: "CUSTOM_FIELDS", commonAccess: true },
  { action: "UPDATE", target: "CUSTOM_FIELDS", commonAccess: true },
  { action: "DELETE", target: "CUSTOM_FIELDS", commonAccess: true },
  /**
   * * CHAT CREDITS Group
   */
  { action: "READ", target: "CHAT_CREDITS", userPermissionIndex: 12 },
  { action: "CREATE", target: "CHAT_CREDITS", userPermissionIndex: 12 },
  { action: "UPDATE", target: "CHAT_CREDITS", userPermissionIndex: 12 },
  { action: "DELETE", target: "CHAT_CREDITS", userPermissionIndex: 12 },
  /**
   * * WALLETS Group
   */
  {
    action: "READ",
    target: "WALLET",
    allowedCountries: ["ID"],
    userPermissionIndex: 13,
  },
  {
    action: "CREATE",
    target: "WALLET",
    allowedCountries: ["ID"],
    userPermissionIndex: 13,
  },
  {
    action: "UPDATE",
    target: "WALLET",
    allowedCountries: ["ID"],
    userPermissionIndex: 13,
  },
  {
    action: "DELETE",
    target: "WALLET",
    allowedCountries: ["ID"],
    userPermissionIndex: 13,
  },
  { action: "READ", target: "HEADER_CHAT_CREDIT", commonAccess: true },
  /**
   * * SETTINGS Group
   */
  { action: "READ", target: "SETTING_STORE", userPermissionIndex: 14 },
  { action: "CREATE", target: "SETTING_STORE", userPermissionIndex: 14 },
  { action: "UPDATE", target: "SETTING_STORE", userPermissionIndex: 14 },
  { action: "DELETE", target: "SETTING_STORE", userPermissionIndex: 14 },
  { action: "READ", target: "SETTING_STORE_COURIER", userPermissionIndex: 14 },
  {
    action: "READ",
    target: "SETTING_STORE_BANK",
    allowedCountries: ["ID"],
    userPermissionIndex: 14,
  },
  { action: "READ", target: "CONVERSION_API", commonAccess: true },
  { action: "READ", target: "SETTING_ADMIN", userPermissionIndex: 15 },
  { action: "CREATE", target: "SETTING_ADMIN", userPermissionIndex: 15 },
  { action: "UPDATE", target: "SETTING_ADMIN", userPermissionIndex: 15 },
  { action: "DELETE", target: "SETTING_ADMIN", userPermissionIndex: 15 },
  // ? User Features Access only open in NEO KKT
  { action: "READ", target: "SETTING_PLAN_PRICING", userPermissionIndex: 16 },
  { action: "CREATE", target: "SETTING_PLAN_PRICING", userPermissionIndex: 16 },
  { action: "UPDATE", target: "SETTING_PLAN_PRICING", userPermissionIndex: 16 },
  { action: "DELETE", target: "SETTING_PLAN_PRICING", userPermissionIndex: 16 },
  {
    action: "READ",
    target: "SUBSCRIPTION_PACKAGE_WABA_PRICE",
    userPermissionIndex: 16,
  },
  { action: "READ", target: "SETTING_ACCOUNT", commonAccess: true },
  { action: "CREATE", target: "SETTING_ACCOUNT", commonAccess: true },
  { action: "UPDATE", target: "SETTING_ACCOUNT", commonAccess: true },
  { action: "DELETE", target: "SETTING_ACCOUNT", commonAccess: true },
  /**
   * * LIVE CHAT
   */
  {
    action: "CREATE",
    target: "CREATE_LIVE_CHAT",
    userPermissionIndex: 26,
    clientPermissionIndex: 27,
  },
  {
    action: "READ",
    target: "CREATE_LIVE_CHAT",
    userPermissionIndex: 26,
    clientPermissionIndex: 27,
  },
  {
    action: "UPDATE",
    target: "EDIT_LIVE_CHAT",
    userPermissionIndex: 27,
    clientPermissionIndex: 27,
  },
  {
    action: "READ",
    target: "EDIT_LIVE_CHAT",
    userPermissionIndex: 27,
    clientPermissionIndex: 27,
  },

  // /**
  //  * * ADVANCE_SEARCH
  //  */
  {
    action: "READ",
    target: "ADVANCE_SEARCH",
    clientPermissionIndex: 30,
  },

  { action: "READ", target: "SHOPIFY", clientPermissionIndex: 29 },
  { action: "CREATE", target: "SHOPIFY", clientPermissionIndex: 29 },
  { action: "UPDATE", target: "SHOPIFY", clientPermissionIndex: 29 },
  { action: "DELETE", target: "SHOPIFY", clientPermissionIndex: 29 },

  /**
   * * ADVANCED_BROADCAST
   */
  {
    action: "READ",
    target: "ADVANCED_BROADCAST",
    clientPermissionIndex: 31,
  },
];

const adminNegativePermissions: PermissionModel[] = [
  { action: "READ", target: "SETTING_STORE_BANK" },
  { action: "READ", target: "SETTING_STORE_SMART_CHAT" },

  { action: "READ", target: "SHOPIFY" },
  { action: "CREATE", target: "SHOPIFY" },
  { action: "UPDATE", target: "SHOPIFY" },
  { action: "DELETE", target: "SHOPIFY" },
];

const customerServicePermissions: PermissionModel[] = [
  { action: "READ", target: "DASHBOARD" },
  { action: "READ", target: "PRODUCT", allowedCountries: ["ID"] },
  { action: "READ", target: "CREATE_PRODUCT", allowedCountries: ["ID"] },
  { action: "CREATE", target: "PRODUCT", allowedCountries: ["ID"] },
  { action: "ACTIVATE", target: "PRODUCT", allowedCountries: ["ID"] },
  { action: "UPDATE", target: "PRODUCT", allowedCountries: ["ID"] },
  { action: "DELETE", target: "PRODUCT", allowedCountries: ["ID"] },

  { action: "READ", target: "CATEGORY", allowedCountries: ["ID"] },
  { action: "CREATE", target: "CATEGORY", allowedCountries: ["ID"] },
  { action: "UPDATE", target: "CATEGORY", allowedCountries: ["ID"] },
  { action: "DELETE", target: "CATEGORY", allowedCountries: ["ID"] },

  { action: "READ", target: "TRANSACTION", allowedCountries: ["ID"] },
  { action: "CREATE", target: "TRANSACTION", allowedCountries: ["ID"] },
  { action: "UPDATE", target: "TRANSACTION", allowedCountries: ["ID"] },
  { action: "DELETE", target: "TRANSACTION", allowedCountries: ["ID"] },

  { action: "READ", target: "CUSTOMER_SERVICE_INBOX" },
  { action: "CREATE", target: "CUSTOMER_SERVICE_INBOX" },
  { action: "UPDATE", target: "CUSTOMER_SERVICE_INBOX" },
  { action: "DELETE", target: "CUSTOMER_SERVICE_INBOX" },

  { action: "READ", target: "CUSTOMER_SERVICE_HISTORY" },
  { action: "CREATE", target: "CUSTOMER_SERVICE_HISTORY" },
  { action: "UPDATE", target: "CUSTOMER_SERVICE_HISTORY" },
  { action: "DELETE", target: "CUSTOMER_SERVICE_HISTORY" },

  { action: "CREATE", target: "CUSTOMER_SERVICE_TRANSACTION_BUTTON_CREATE" },

  { action: "READ", target: "CONTACT" },
  { action: "CREATE", target: "CONTACT" },
  { action: "UPDATE", target: "CONTACT" },
  { action: "DELETE", target: "CONTACT" },
  {
    action: "READ",
    target: "CUSTOM_DASHBOARD",
  },
];

export default function defineAbilityFor(abilityModel: AbilityDefinitionModel) {
  const data: AbilityDataModel = {
    allPermissions: allPermission,
    adminNegativePermissions: adminNegativePermissions,
    customerServicePermissions: customerServicePermissions,
  };

  return AbilityHelper.getInstance().attachAbility(data, abilityModel);
}
